<template>
  <div class="game-container">
    <WriteHanziPage
      :rightImgList="rightImgList"
      :strokeStepImgList="strokeStepImgList"
      :hanziInfo="hanziInfo"
    />
  </div>
</template>

<script>
import WriteHanziPage from "@/components/Course/CoursePage/WriteHanziPage";
export default {
  data() {
    return {
      hanziInfo: {
        hanziBgImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-12/hanzi-ge-grey-border.svg"),
        strokeRemindImg: require("@/assets/img/16-GAMES/G20-writing-hanzi/lesson-12/hanzi-ge-stroke-order.svg"),
        pinyin: "ge",
      },
      hanziStrokIndexArr:[1,3,5,7]
    };
  },
  computed: {
    rightImgList() {
      const imgArr = [];
      for (let i = 0; i <= 3; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-12/grapes-${this.hanziStrokIndexArr[i]}.svg`)
        );
      }
      return imgArr;
    },
    strokeStepImgList() {
      const imgArr = [];
      for (let i = 1; i <= 3; i++) {
        imgArr.push(
          require(`@/assets/img/16-GAMES/G20-writing-hanzi/lesson-12/lesson-12-ge-${i}.gif`)
        );
      }
      return imgArr;
    },
  },
  components: {
    WriteHanziPage,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











